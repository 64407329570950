import { isNotNullish } from '@/utils/common/isNullish';

const defaultUnit = '個';

export const formatUnit = (unit: string | null | undefined) => {
  return unit ? unit : defaultUnit;
};

export const isReplenishmentRequired = (stock: number, orderPoint: number | null | undefined) => {
  return isNotNullish(stock) && isNotNullish(orderPoint) && stock <= orderPoint;
};
